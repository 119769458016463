import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-agri-marketing'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search, labelData) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
          columns: reportHeadData.reportHeadColumn, style: 'main_head'
        },
        { text: reportHeadData.address, style: 'address', alignment: 'center' }
      ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDivisionName(search.division_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.district'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDistrictName(search.district_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.upazila'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getUpazilaNameCustom(search.upazila_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.market'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getMarketName(search.market_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_group_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getCommodityGroupName(search.com_grp_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_sub_group'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getCommoditySubGroupName(search.com_subgrp_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getCommodityName(search.commodity_id), alignment: 'left', style: 'search' }
          ]
        ]
        if (search.select_type !== 'daily') {
          allRowsHead.push(
            [
              { text: vm.$t('crop_price_config.year'), alignment: 'right', style: 'search' },
              { text: ':', style: 'search', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? search.year_bn : search.year_en, alignment: 'left', style: 'search' }
            ],
            [
              { text: vm.$t('crop_price_config.month'), alignment: 'right', style: 'search' },
              { text: ':', style: 'search', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? search.month_bn : search.month_en, alignment: 'left', style: 'search' }
            ],
            [
              { text: vm.$t('crop_price_config.week'), alignment: 'right', style: 'search' },
              { text: ':', style: 'search', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? search.week_bn : search.week_en, alignment: 'left', style: 'search' }
            ]
          )
        } else {
          allRowsHead.push(
            [
              { text: vm.$t('globalTrans.from_date'), alignment: 'right', style: 'search' },
              { text: ':', style: 'search', alignment: 'center' },
              { text: dateFormat(search.from_date), alignment: 'left', style: 'search' }
            ],
            [
              { text: vm.$t('globalTrans.end_date'), alignment: 'right', style: 'search' },
              { text: ':', style: 'search', alignment: 'center' },
              { text: dateFormat(search.end_date), alignment: 'left', style: 'search' }
            ]
          )
        }
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['19%', '1%', '80%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const labelDatas = labelData.filter(item => item.show === '1')
        const headRows = []
        const width = []
        labelDatas.forEach((info, index) => {
          headRows.push(
            { text: (i18n.locale === 'bn') ? info.label_bn : info.label_en, style: 'th', alignment: 'center', bold: true }
          )
          width.push('*')
        })
        var serial = 0
        const listData = newDatas.map(item => {
          serial += 1
          const rowData = labelDatas.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
              return { text: vm.$n(serial), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'commodity.unit_retail.unit_name') {
              return { text: item.commodity.unit_retail.unit_name, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'commodity.unit_retail.unit_name_bn') {
              return { text: item.commodity.unit_retail.unit_name_bn, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'r_lowestPrice') {
              return { text: vm.$n(parseFloat(item.r_lowestPrice).toFixed(2), { useGrouping: false }) + '-' + vm.$n(parseFloat(item.r_highestPrice).toFixed(2), { useGrouping: false }), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'w_lowestPrice') {
              return { text: vm.$n(parseFloat(item.w_lowestPrice).toFixed(2), { useGrouping: false }) + '-' + vm.$n(parseFloat(item.w_highestPrice).toFixed(2), { useGrouping: false }), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'w_avgPrice') {
              return { text: vm.$n(parseFloat(item.w_avgPrice).toFixed(2), { useGrouping: false }), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'r_avgPrice') {
              return { text: vm.$n(parseFloat(item.r_avgPrice).toFixed(2), { useGrouping: false }), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'commodity.unit_whole_sale.unit_name_bn') {
              return { text: item.commodity.unit_whole_sale.unit_name_bn, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'commodity.unit_whole_sale.unit_name') {
              return { text: item.commodity.unit_whole_sale.unit_name, style: 'td', alignment: 'center' }
            }
            return { text: item[keyItem.key], style: 'td', alignment: 'center' }
          })
          return rowData
        })
        listData.unshift(headRows)
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: width,
            body: listData
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 7 : 9,
            margin: [0, 0, 0, 0]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 7 : 9,
            margin: [0, 0, 0, 0]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('product-wise-market-price-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
