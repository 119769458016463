<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('damReport.product_wise_market_price_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" vid="division_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.division') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="division_id"
                                  v-model="values_division"
                                  search
                                  historyButton
                                  :filters="filters_division"
                                  :options="options"
                                  :selectOptions="divisionList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="District" vid="district_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="district_id"
                                  v-model="values_district"
                                  search
                                  historyButton
                                  :filters="filters_district"
                                  :options="options"
                                  :selectOptions="districtList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upazila_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('globalTrans.upazila') }} <span class="text-danger">*</span>
                                  </template>
                                  <vue-multi-select
                                  id="upazila_id"
                                  v-model="values_upazila"
                                  search
                                  historyButton
                                  :filters="filters_upazila"
                                  :options="options"
                                  :selectOptions="upazilaList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                  <div class="invalid-feedback d-block"   v-if="errors.length && clickSubmit">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Market" vid="market_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="market_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('crop_price_config.market') }} <span class="text-danger">*</span>
                                  </template>
                                  <vue-multi-select
                                  id="market_id"
                                  v-model="values_market"
                                  search
                                  historyButton
                                  :filters="filters_market"
                                  :options="options"
                                  :selectOptions="marketList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                  <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Group" vid="com_grp_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_group') }} <span class="text-danger">*</span>
                            </template>
                            <!-- <b-form-select
                              plain
                              v-model="formData.com_grp_id"
                              :options="commodityGroupList"
                              id="com_grp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select> -->
                            <vue-multi-select
                              id="com_grp_id"
                              v-model="values_group"
                              search
                              historyButton
                              :filters="filters_group"
                              :options="options"
                              :selectOptions="commodityGroupList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              class="w-100"
                              :btnLabel="btnLabel"
                              btnClass="w-100 d-block text-left"
                              />
                              <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Sub Group" vid="com_subgrp_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_sub_group') }} <span class="text-danger">*</span>
                            </template>
                            <!-- <b-form-select
                              plain
                              v-model="formData.com_subgrp_id"
                              :options="commoditySubGroupList"
                              id="com_subgrp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select> -->
                              <vue-multi-select
                              id="com_subgrp_id"
                              v-model="values_sub_group"
                              search
                              historyButton
                              :filters="filters_sub_group"
                              :options="options"
                              :selectOptions="commoditySubGroupList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              class="w-100"
                              :btnLabel="btnLabel"
                              btnClass="w-100 d-block text-left"
                              />
                              <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Commodity Name" vid="commodity_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="commodity_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_name') }} <span class="text-danger">*</span>
                            </template>
                            <vue-multi-select
                              id="commodity_id"
                              v-model="values_commodity"
                              search
                              historyButton
                              :filters="filters_commodity"
                              :options="options"
                              :selectOptions="commodityNameList"
                              :state="errors[0] ? false : (valid ? true : null)"
                              class="w-100"
                              :btnLabel="btnLabel"
                              btnClass="w-100 d-block text-left"
                              />
                            <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                              {{ errors[0] }}
                            </div>
                            <!-- <b-form-select
                              plain
                              v-model="formData.commodity_id"
                              :options="commodityNameList"
                              id="commodity_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div> -->
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="12" md="12" sm="12">
                        <div class="pt-4 pb-4">
                          <ValidationProvider name="Select Type" vid="select_type" rules="">
                            <b-form-radio-group
                              v-model="formData.select_type"
                              :options="timePreiodList"
                            >
                            </b-form-radio-group>
                          </ValidationProvider>
                        </div>
                      </b-col>
                      <b-col lg="4" md="4" sm="12" v-if="formData.select_type ==='weekly'">
                        <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="year"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('crop_price_config.year') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.year"
                            :options="yearList"
                            id="year"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12" v-if="formData.select_type ==='weekly'">
                          <ValidationProvider name="Month" vid="month_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="month_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('crop_price_config.month') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="formData.month_id"
                                  :options="monthList"
                                  id="month_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12" v-if="formData.select_type ==='weekly'">
                          <ValidationProvider name="Week" vid="week_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="week_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('crop_price_config.week') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="formData.week_id"
                                  :options="weekList"
                                  id="week_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="12" :class="{ hide: formData.select_type ==='weekly' }">
                          <ValidationProvider name="From Date" vid="from_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="from_date"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.from_date')}} <span class="text-danger">*</span>
                              </template>
                                  <b-form-input
                                  class="datepicker"
                                  v-model="formData.from_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  placeholder="yyyy-mm-dd"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                       <b-col lg="5" sm="12" :class="{ hide: formData.select_type ==='weekly' }">
                          <ValidationProvider name="To Date" vid="to_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="to_date"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.to_date')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  class="datepicker1"
                                  v-model="formData.to_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  placeholder="yyyy-mm-dd"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="2" sm="12">
                        <b-dropdown split split-variant="outline-primary" variant="primary" id="dropdown-form" :text="$t('damReport.select_filed')" ref="dropdown">
                          <b-dropdown-form>
                            <div
                              class="form-group col-12 my-0 py-0"
                              v-for="(field, index) in labelData"
                              :key="index"
                              >
                              <b-form-checkbox
                                :id="'checkbox-' + field.label_en"
                                v-model="field.show"
                                :name="'checkbox-' + field.label_en"
                                value=1
                                unchecked-value=0
                                @change="excelInputChange()"
                              >
                                {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                              </b-form-checkbox>
                            </div>
                          </b-dropdown-form>
                        </b-dropdown>
                      </b-col>
                      <!-- <b-col lg="6" sm="12"> -->
                      <!-- </b-col> -->
                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2" @click="clickSubmit=true">{{ $t('globalTrans.search') }}</b-button>
                        </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('damReport.product_wise_market_price_report') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <b-button class="btn_add_new mr-2" @click="pdfExport">
                    <i class="far fa-file-pdf"></i>{{ $t('globalTrans.export_pdf') }}
                  </b-button>
                  <export-excel
                      class="btn btn_add_new"
                      :data="dataCustomize"
                      :fields= "json_fields"
                      :title="headerValue"
                      name="product-wise-market-price-report.xls"
                      :default-value="headerValue">
                      <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                  </export-excel>
                </template>
                <template v-slot:body>
                  <b-overlay>
                    <b-row mt-5>
                      <b-col style="border: 2px solid;margin:0px;padding:0px" md="12" class="table-responsive" v-if='datas.length'>
                        <b-row>
                          <b-col>
                            <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="13">
                              <template v-slot:projectNameSlot>
                                {{ }}
                              </template>
                              {{ $t('damReport.product_wise_market_price_report') }}
                            </list-report-head>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-center search-body-report">
                              <table style="width:100%;color:black;">
                                <tr>
                                  <td class="align-top" align="right" width="20%">{{ $t('globalTrans.division') }}</td>
                                  <td class="align-top" align="center" width="5%">:</td>
                                  <td class="align-top" align="left" width="75%" >{{ getDivisionName(formData.division_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('globalTrans.district') }}</td>
                                  <td class="align-top" align="center" width="5%">:</td>
                                  <td class="align-top" align="left" >{{ getDistrictName(formData.district_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('globalTrans.upazila') }}</td>
                                  <td class="align-top" align="center" width="5%">:</td>
                                  <td class="align-top" align="left" >{{ getUpazilaNameCustom(formData.upazila_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('crop_price_config.market') }}</td>
                                  <td class="align-top" align="center" width="5%">:</td>
                                  <td class="align-top" align="left" >{{ getMarketName(formData.market_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('crop_price_config.commodity_group_name') }}</td>
                                  <td class="align-top" align="center">:</td>
                                  <!-- <td class="align-top" align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_group_name_bn : formData.commodity_group_name_en }}</td> -->
                                  <td class="align-top" align="left" style="width:10%">{{ getCommodityGroupName(formData.com_grp_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                  <td class="align-top" align="center">:</td>
                                  <!-- <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? formData.commodity_sub_group_name_bn : formData.commodity_sub_group_name_en }}</td> -->
                                  <td class="align-top" align="left">{{ getCommoditySubGroupName(formData.com_subgrp_id) }}</td>
                                </tr>
                                <tr>
                                  <td class="align-top" align="right">{{ $t('crop_price_config.commodity_name') }}</td>
                                  <td class="align-top" align="center">:</td>
                                  <td class="align-top" align="left">{{ getCommodityName(formData.commodity_id) }}</td>
                                </tr>
                                <template v-if="formData.select_type === 'daily'">
                                  <tr>
                                    <td class="align-top" align="right">{{ $t('globalTrans.from_date') }}</td>
                                    <td class="align-top" align="center" width="5%">:</td>
                                    <td class="align-top" align="left">{{ formData.from_date | dateFormat }}</td>
                                  </tr>
                                  <tr>
                                    <td class="align-top" align="right">{{ $t('globalTrans.to_date') }}</td>
                                    <td class="align-top" align="center" width="5%">:</td>
                                    <td class="align-top" align="left">{{ formData.to_date | dateFormat }}</td>
                                  </tr>
                                </template>
                                <template v-else>
                                  <tr>
                                    <td class="align-top" align="right" >{{ $t('crop_price_config.year') }}</td>
                                    <td class="align-top" align="center" width="5%">:</td>
                                    <td class="align-top" align="left" >{{ ($i18n.locale==='bn') ? formData.year_bn : formData.year_en  }}</td>
                                  </tr>
                                  <tr>
                                    <td class="align-top" align="right" >{{ $t('crop_price_config.month') }}</td>
                                    <td class="align-top" align="center" width="5%">:</td>
                                    <td class="align-top" align="left" >
                                      {{ ($i18n.locale==='bn') ? formData.month_bn : formData.month_en }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="align-top" align="right" >{{ $t('crop_price_config.week') }}</td>
                                    <td class="align-top" align="center" width="5%">:</td>
                                    <td class="align-top" align="left" >{{ ($i18n.locale==='bn') ? formData.week_bn : formData.week_en }}</td>
                                  </tr>
                                </template>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="12"  class="main-body-report steaky-header">
                            <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                              <b-table striped head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                <template v-slot:cell(r_lowestPrice)="data">
                                  {{ parseFloat(data.item.r_lowestPrice) && parseFloat(data.item.r_highestPrice) && $n(parseFloat(data.item.r_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.r_highestPrice).toFixed(2)) }}
                                </template>
                                <template v-slot:cell(year)="data">
                                  {{ data.item.year }}
                                  <!-- {{  $n(data.item.year, { useGrouping: false }) }} -->
                                </template>
                                <template v-slot:cell(w_lowestPrice)="data">
                                  {{ $n(parseFloat(data.item.w_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.w_highestPrice).toFixed(2)) }}
                                </template>
                                <template v-slot:cell(w_avgPrice)="data">
                                  {{ $n(parseFloat(data.item.w_avgPrice).toFixed(2)) }}
                                </template>
                                <template v-slot:cell(r_avgPrice)="data">
                                  {{ $n(parseFloat(data.item.r_avgPrice).toFixed(2)) }}
                                </template>
                              </b-table>
                            </slot>
                          </b-col>
                        </b-row>
                      </b-col>
                      <div class="panel-body text-center mt-3 col-12" v-else>
                        <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                      </div>
                    </b-row>
                  </b-overlay>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.hide {
  visibility: hidden !important;
}
.main-body-report th {
  font-size: 12px !important;
}
.main-body-report td {
  font-size: 12px !important;
}
.search-body-report th {
  font-size: 13px !important;
}
.search-body-report td {
  font-size: 13px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import { dateFormat } from '@/Utils/fliter'
import Store from '@/store'
import Vue from 'vue'
import i18n from '@/i18n'
import excel from 'vue-excel-export'
// import moment from 'moment'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'
Vue.use(excel)
const today = new Date().toISOString().substr(0, 10)

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.division, label_bn: i18n.messages.bn.globalTrans.division, class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'division', order: 1 },
  { label_en: i18n.messages.en.globalTrans.district, label_bn: i18n.messages.bn.globalTrans.district, class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'district', order: 2 },
  { label_en: i18n.messages.en.globalTrans.upazila, label_bn: i18n.messages.bn.globalTrans.upazila, class: 'text-left', sortable: true, stickyColumn: true, show: '1', excelKey: 'upazilla', order: 3 },
  { label_en: i18n.messages.en.crop_price_config.market_name, label_bn: i18n.messages.bn.crop_price_config.market_name, class: 'text-left', sortable: true, show: '1', excelKey: 'market', order: 4 },
  { label_en: i18n.messages.en.crop_price_config.commodity_name, label_bn: i18n.messages.bn.crop_price_config.commodity_name, class: 'text-left', sortable: true, show: '1', excelKey: 'commodity_name', order: 5 },
  { label_en: i18n.messages.en.globalTrans.year, label_bn: i18n.messages.bn.globalTrans.year, class: 'text-left', sortable: true, show: '1', excelKey: 'year', order: 6 },
  { label_en: i18n.messages.en.crop_price_config.unit_retail, label_bn: i18n.messages.bn.crop_price_config.unit_retail, class: 'text-left', sortable: true, show: '1', excelKey: 'unit_retail', order: 7 },
  { label_en: i18n.messages.en.damReport.retail_price, label_bn: i18n.messages.bn.damReport.retail_price, class: 'text-left', sortable: true, show: '1', excelKey: 'retail_price', order: 8 },
  { label_en: i18n.messages.en.crop_price_config.AveragePrice, label_bn: i18n.messages.bn.crop_price_config.AveragePrice, class: 'text-left', sortable: true, show: '1', excelKey: 'r_average_price', order: 9 },
  { label_en: i18n.messages.en.crop_price_config.unit_whole_sale, label_bn: i18n.messages.bn.crop_price_config.unit_whole_sale, class: 'text-left', sortable: true, show: '1', excelKey: 'unit_whole_sale', order: 10 },
  { label_en: i18n.messages.en.damReport.wholesale_price, label_bn: i18n.messages.bn.damReport.wholesale_price, class: 'text-left', sortable: true, show: '1', excelKey: 'wholesale_price', order: 11 },
  { label_en: i18n.messages.en.crop_price_config.AveragePriceW, label_bn: i18n.messages.bn.crop_price_config.AveragePriceW, class: 'text-left', sortable: true, show: '1', excelKey: 'w_averagePrice', order: 12 }
]
export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider,
      ListReportHead,
      vueMultiSelect
    },
    created () {
      if (this.id) {
        const tmp = this.getEditingData()
        this.formData = tmp
      }
      // this.loadData()
      this.labelData = this.labelList
      this.excelInputChange()
    },
    data () {
      return {
        btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
        show_fields: false,
        noCollapse: false,
        stickyHeader: true,
        AveragePriceText: '',
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        formData: {
            division_id: [],
            district_id: [],
            upazila_id: [],
            market_id: [],
            com_grp_id: [],
            com_subgrp_id: [],
            commodity_id: [],
            select_type: 'daily',
            price_type_id: [],
            year: 0,
            month_id: 0,
            week_id: 0,
            from_date: today,
            to_date: today,
            commodity_group_name_en: '',
            commodity_group_name_bn: '',
            commodity_sub_group_name_en: '',
            commodity_sub_group_name_bn: '',
            commodity_name_bn: '',
            commodity_name_en: '',
            year_en: '',
            year_bn: '',
            month_en: '',
            month_bn: '',
            week_en: '',
            week_bn: '',
            divisionList: [],
            districtList: [],
            upazillaList: [],
            marketList: []
        },
        headerExcelDefault: {
          orgName: null,
          orgNameBn: null,
          address: null,
          address_bn: null
        },
        districtList: [],
        upazilaList: [],
        marketList: [],
        currentDate: '',
        listItemofyerterday: '',
        commoditySubGroupList: [],
        commodityNameList: [],
        warehouseServiceBaseUrl: warehouseServiceBaseUrl,
        datas: [],
        timePreiodList: [
          {
            value: 'daily',
            text: this.$i18n.locale === 'en' ? 'Daily' : 'দৈনিক'
          },
          {
            value: 'weekly',
            text: this.$i18n.locale === 'en' ? 'Weekly' : 'সাপ্তাহিক'
          }
        ],
        labelData: [],
        excelColumnBn: {},
        excelColumn: {},
        showData: false,
        values_division: [],
        values_district: [],
        values_upazila: [],
        values_market: [],
        values_group: [],
        values_sub_group: [],
        values_commodity: [],
        filters_division: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_commodity: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_district: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_upazila: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_market: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_group: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        filters_sub_group: [{
          nameAll: this.$t('globalTrans.select_all'),
          nameNotAll: this.$t('globalTrans.deselect_all'),
          func () {
            return true
          }
        }],
        options: {
          multi: true,
          groups: false,
          labelName: 'text',
          labelValue: 'value',
          cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
        },
        clickSubmit: false
      }
    },
    mounted () {
      core.index()
      flatpickr('.datepicker', {})
      flatpickr('.datepicker1', {})
      this.AveragePriceText = (parseInt(this.formData.HighestPrice) + parseInt(this.formData.LowestPrice)) / 2
    },
    computed: {
      labelList: function () {
        const dataList = defaultColumn
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
              const data = { value: item.label_en, text: item.label_bn }
              return Object.assign({}, item, data)
            } else {
              const data = { value: item.label_en, text: item.label_en }
              return Object.assign({}, item, data)
            }
        })
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          const labelData = {}
          labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
          return Object.assign(item, labelData)
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'division_name_bn' },
            { key: 'district_name_bn' },
            { key: 'upazila_name_bn' },
            { key: 'market_name_bn' },
            { key: 'commodity_name_bn' },
            { key: 'year' },
            { key: 'unit_name_bn' },
            { key: 'r_lowestPrice' },
            { key: 'r_avgPrice' },
            { key: 'w_unit_name_bn' },
            { key: 'w_lowestPrice' },
            { key: 'w_avgPrice' }
          ]
        } else {
          keys = [
            { key: 'division_name_en' },
            { key: 'district_name_en' },
            { key: 'upazila_name_en' },
            { key: 'commodity_name_en' },
            { key: 'year' },
            { key: 'unit_name' },
            { key: 'r_lowestPrice' },
            { key: 'r_avgPrice' },
            { key: 'w_unit_name' },
            { key: 'w_lowestPrice' },
            { key: 'w_avgPrice' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('damReport.product_wise_market_price_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.formData.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.formData.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.getCommodityGroupName(this.formData.com_grp_id)
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.getCommoditySubGroupName(this.formData.com_subgrp_id)
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.getCommodityName(this.formData.commodity_id)
          if (this.formData.select_type === 'weekly') {
            headerVal[10] = this.$t('crop_price_config.year') + ':  ' + this.formData.year_en
            headerVal[11] = this.$t('crop_price_config.month') + ': ' + this.formData.month_en + '; '
            headerVal[12] = this.$t('crop_price_config.week') + ' : ' + this.formData.week_en
          } else {
            headerVal[10] = this.$t('globalTrans.from_date') + ':  ' + dateFormat(this.formData.from_date)
            headerVal[11] = this.$t('globalTrans.end_date') + ':  ' + dateFormat(this.formData.end_date)
          }
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('damReport.product_wise_market_price_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.formData.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.formData.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.getCommodityGroupName(this.formData.com_grp_id)
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.getCommoditySubGroupName(this.formData.com_subgrp_id)
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.getCommodityName(this.formData.commodity_id)
          if (this.formData.select_type === 'weekly') {
            headerVal[10] = this.$t('crop_price_config.year') + ':  ' + this.formData.year_bn
            headerVal[11] = this.$t('crop_price_config.month') + ': ' + this.formData.month_bn + '; '
            headerVal[12] = this.$t('crop_price_config.week') + ' : ' + this.formData.week_bn
          } else {
            headerVal[10] = this.$t('globalTrans.from_date') + ':  ' + dateFormat(this.formData.from_date)
            headerVal[11] = this.$t('globalTrans.end_date') + ':  ' + dateFormat(this.formData.end_date)
          }
        }
        return headerVal
      },
      json_fields: function () {
        return this.$i18n.locale === 'bn' ? this.excelColumnBn : this.excelColumn
      },
      dataCustomize () {
        try {
            if (typeof this.datas !== 'undefined') {
                const listData = this.datas
                // var slNo = 1
                listData.map(item => {
                    if (this.$i18n.locale === 'bn') {
                        // item.sl_no = this.$n(slNo++)
                        item.division = item.division_name_bn
                        item.district = item.district_name_bn
                        item.upazilla = item.upazila_name_bn
                        item.market = item.market_name_bn
                        item.commodity_name = item.commodity_name_bn
                        item.year = this.$n(item.year, { useGrouping: false })
                        item.unit_retail = item.unit_name_bn
                        item.retail_price = this.$n(parseFloat(item.r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.r_highestPrice).toFixed(2))
                        item.r_average_price = this.$n(parseFloat(item.r_avgPrice).toFixed(2))
                        item.unit_whole_sale = item.w_unit_name_bn
                        item.wholesale_price = this.$n(parseFloat(item.w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.w_highestPrice).toFixed(2))
                        item.w_averagePrice = this.$n(parseFloat(item.w_avgPrice).toFixed(2))
                    } else {
                        // item.sl_no = slNo++
                      item.division = item.division_name_en
                      item.district = item.district_name_en
                      item.upazilla = item.upazila_name_en
                      item.market = item.market_name_en
                      item.commodity_name = item.commodity_name_en
                      item.year = this.$n(item.year, { useGrouping: false })
                      item.unit_retail = item.unit_name
                      item.retail_price = this.$n(parseFloat(item.r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.r_highestPrice).toFixed(2))
                      item.r_average_price = this.$n(parseFloat(item.r_avgPrice).toFixed(2))
                      item.unit_whole_sale = item.w_unit_name
                      item.wholesale_price = this.$n(parseFloat(item.w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.w_highestPrice).toFixed(2))
                      item.w_averagePrice = this.$n(parseFloat(item.w_avgPrice).toFixed(2))
                    }
                    return Object.assign({}, item)
                })
                return listData
            } else {
                return []
            }
        } catch (e) {
            return []
        }
      },
      divisionList: function () {
        return this.$store.state.Portal.agriObj.divisionList
      },
      yearList: function () {
        return this.$store.state.Portal.agriObj.yearList
      },
      monthList: function () {
        return this.$store.state.Portal.agriObj.monthList
      },
      weekList: function () {
        return this.$store.state.Portal.agriObj.weekList
      },
      commodityGroupList: function () {
        return this.$store.state.Portal.agriObj.commodityGroupList
      },
      priceEntryTypeList: function () {
        return this.$store.state.Portal.agriObj.priceEntryTypeList
      },
      i18 () {
        return this.$i18n.locale
      },
      priceTypeList: function () {
        return this.$store.state.Portal.agriObj.priceTypeList
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    watch: {
      values_division: function (newVal) {
        if (newVal.length) {
          this.districtList = this.getDistrictList(newVal)
        } else {
          this.districtList = []
        }
      },
      values_district: function (newVal) {
        if (newVal.length) {
          this.upazilaList = this.getUpazilaList(newVal)
        } else {
          this.upazilaList = []
        }
      },
      values_upazila: function (newVal) {
        if (newVal.length) {
          this.marketList = this.getMarketList(newVal)
        } else {
          this.marketList = []
        }
      },
      values_group: function (newVal) {
        if (newVal.length) {
          this.commoditySubGroupList = this.getCommoditySubGroupList(newVal)
        } else {
          this.commoditySubGroupList = []
        }
      },
      // 'formData.com_grp_id': function (newVal, oldVal) {
      //   if (newVal !== oldVal) {
      //     this.commoditySubGroupList = this.getCommoditySubGroupList(newVal)
      //   }
      // },
      // 'formData.com_subgrp_id': function (newVal, oldVal) {
      //   if (newVal !== oldVal) {
      //     this.commodityNameList = this.getCommodityNameList(newVal)
      //   }
      // }
      values_sub_group: function (newVal) {
        if (newVal.length) {
          this.commodityNameList = this.getCommodityNameList(newVal)
        } else {
          this.commodityNameList = []
        }
      }
    },
    methods: {
      excelInputChange () {
        const labelColumn = {}
        const labelColumnBn = {}
        this.labelData.map((item, index) => {
          if (item.show === '1') {
            labelColumn[item.label_en] = item.excelKey
            labelColumnBn[item.label_bn] = item.excelKey
          }
        })
        this.excelColumn = labelColumn
        this.excelColumnBn = labelColumnBn
      },
      // getCommoditySubGroupList (commodityGroupId) {
      //   const commoditySubGroupList = this.$store.state.Portal.agriObj.commoditySubGroupList.filter(item => item.status === 1 && item.commodity_group_id === commodityGroupId)
      //   return commoditySubGroupList.map(item => {
      //     if (this.$i18n.locale === 'bn') {
      //       return { value: item.value, text: item.text_bn }
      //     } else {
      //       return { value: item.value, text: item.text }
      //     }
      //   })
      // },
      // getCommodityNameList (commoditySubGroupId) {
      //   const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.filter(item => item.status === 1 && item.commodity_sub_group_id === commoditySubGroupId)
      //   return commodityNameList.map(item => {
      //     if (this.$i18n.locale === 'bn') {
      //       return { value: item.value, text: item.text_bn }
      //     } else {
      //       return { value: item.value, text: item.text }
      //     }
      //   })
      // },
      pdfExport () {
        const reportTitle = this.$t('damReport.product_wise_market_price_report')
        ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.formData, this.labelData)
      },
      getEditingData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      async loadData () {
          RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
            if (response.success) {
              const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
              const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
              const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
              this.headerExcelDefault.orgName = orgName
              this.headerExcelDefault.orgNameBn = orgNameBn
              this.headerExcelDefault.address = response.data.address
              this.headerExcelDefault.address_bn = response.data.address_bn
            }
          })
            Store.commit('mutateCommonProperties', {
              loading: true
            })
            this.showData = true
            const commoditySubGroupList = this.$store.state.Portal.agriObj.commoditySubGroupList
            const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList
            const commodityGroup = this.commodityGroupList.find(item => item.value === this.formData.com_grp_id)
            this.formData.commodity_group_name_en = typeof commodityGroup !== 'undefined' ? commodityGroup.text_en : ''
            this.formData.commodity_group_name_bn = typeof commodityGroup !== 'undefined' ? commodityGroup.text_bn : ''
            const commoditySubGroup = commoditySubGroupList.find(item => item.value === this.formData.com_subgrp_id)
            this.formData.commodity_sub_group_name_en = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_en : ''
            this.formData.commodity_sub_group_name_bn = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_bn : ''
            const commodityName = commodityNameList.find(item => item.commodity_sub_group_id === this.formData.com_subgrp_id)
            this.formData.commodity_name_en = typeof commodityName !== 'undefined' ? commodityName.text_en : ''
            this.formData.commodity_name_bn = typeof commodityName !== 'undefined' ? commodityName.text_bn : ''
            const yearListObj = this.$store.state.Portal.agriObj.yearList.find(item => item.value === this.formData.year)
            this.formData.year_en = typeof yearListObj !== 'undefined' ? yearListObj.text_en : ''
            this.formData.year_bn = typeof yearListObj !== 'undefined' ? yearListObj.text_bn : ''
            const monthListObj = this.$store.state.commonObj.monthList.find(item => item.value === this.formData.month_id)
            this.formData.month_en = typeof monthListObj !== 'undefined' ? monthListObj.text_en : ''
            this.formData.month_bn = typeof monthListObj !== 'undefined' ? monthListObj.text_bn : ''
            const weekListObj = this.$store.state.Portal.agriObj.weekList.find(item => item.value === this.formData.week_id)
            this.formData.week_en = typeof weekListObj !== 'undefined' ? weekListObj.text_en : ''
            this.formData.week_bn = typeof weekListObj !== 'undefined' ? weekListObj.text_bn : ''
            const marketIds = []
            this.values_market.forEach(item => {
              marketIds.push(parseInt(item.value))
            })
            this.formData.market_id = marketIds
            const commodityIds = []
            this.values_commodity.forEach(item => {
              commodityIds.push(parseInt(item.value))
            })
            this.formData.commodity_id = commodityIds
            await RestApi.postData(agriMarketingServiceBaseUrl, '/crop-price-info/reports/price-report/product-wise-price-report', this.formData).then(response => {
              if (response.success) {
                  Store.commit('mutateCommonProperties', {
                    loading: false
                  })
                  if (response.data) {
                    this.datas = this.getRelationalData(response.data)
                  }
                  Store.commit('mutateCommonProperties', {
                    loading: false
                  })
              } else {
                  this.datas = []
                  Store.commit('mutateCommonProperties', {
                    loading: false
                  })
              }
              this.clickSubmit = false
            })
      },
      getRelationalData (data) {
        const divisionList = this.$store.state.Portal.agriObj.divisionList
        const districtList = this.$store.state.Portal.agriObj.districtList
        const upazilaList = this.$store.state.Portal.agriObj.upazilaList
        const marketList = this.$store.state.Portal.agriObj.marketList
        return data.map(item => {
          const division = divisionList.find(div => div.value === item.division_id)
          const divObj = { division_name_en: typeof division !== 'undefined' ? division.text_en : '', division_name_bn: typeof division !== 'undefined' ? division.text_bn : '' }
          const district = districtList.find(dist => dist.value === item.district_id)
          const distObj = { district_name_en: typeof district !== 'undefined' ? district.text_en : '', district_name_bn: typeof district !== 'undefined' ? district.text_bn : '' }
          const upazila = upazilaList.find(upz => upz.value === item.upazila_id)
          const upzObj = { upazila_name_en: typeof upazila !== 'undefined' ? upazila.text_en : '', upazila_name_bn: typeof upazila !== 'undefined' ? upazila.text_bn : '' }
          const market = marketList.find(mk => mk.value === item.market_id)
          const marketObj = { market_name_en: typeof market !== 'undefined' ? market.text_en : '', market_name_bn: typeof market !== 'undefined' ? market.text_bn : '' }
          const rUnit = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
          const wUnit = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_wholesale)
          const rUnitObj = {
            unit_name: typeof rUnit !== 'undefined' ? rUnit.text_en : '',
            unit_name_bn: typeof rUnit !== 'undefined' ? rUnit.text_bn : ''
          }
          const wUnitObj = {
            w_unit_name: typeof wUnit !== 'undefined' ? wUnit.text_en : '',
            w_unit_name_bn: typeof wUnit !== 'undefined' ? wUnit.text_bn : ''
          }
          const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.find(commodityNameList => commodityNameList.value === item.commodity_id)
          const commObj = {
            commodity_name_en: typeof commodityNameList !== 'undefined' ? commodityNameList.text_en : '',
            commodity_name_bn: typeof commodityNameList !== 'undefined' ? commodityNameList.text_bn : ''
          }
          return Object.assign({}, item, divObj, distObj, upzObj, marketObj, rUnitObj, wUnitObj, commObj)
        })
      },
      getDivisionName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getDistrictList (divisionList) {
        const divisionIds = []
        divisionList.forEach(item => {
          divisionIds.push(parseInt(item.value))
        })
        this.formData.division_id = divisionIds
        this.districtList = this.$store.state.Portal.agriObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
        if (this.values_district.length) {
          const arr = []
          this.values_district.forEach(item => {
            const obj = this.districtList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_district = arr
        }
        return this.districtList
      },
      getDistrictName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.districtList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getUpazilaList (districtList) {
        const districtIds = []
        districtList.forEach(item => {
          districtIds.push(parseInt(item.value))
        })
        this.formData.district_id = districtIds
        this.upazilaList = this.$store.state.Portal.agriObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.values_upazila.length) {
          const arr = []
          this.values_upazila.forEach(item => {
            const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_upazila = arr
        }
        return this.upazilaList
      },
      getUpazilaName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getUpazilaNameCustom (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const market = this.$store.state.Portal.agriObj.marketList.find(item => item.upazila_id === parseInt(id))
            if (market !== undefined) {
              const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            }
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getMarketList (upazilaList) {
        const upazilaIds = []
        upazilaList.forEach(item => {
          upazilaIds.push(parseInt(item.value))
        })
        this.formData.upazila_id = upazilaIds
        this.marketList = this.$store.state.Portal.agriObj.marketList.filter(item => upazilaIds.includes(item.upazila_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.values_market.length) {
          const arr = []
          this.values_market.forEach(item => {
            const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_market = arr
        }
        return this.marketList
      },
      getMarketName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.marketList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      // getCommodityName (ids) {
      //   if (Array.isArray(ids)) {
      //     var arr = []
      //     ids.forEach(id => {
      //       const obj = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === parseInt(id))
      //       arr.push(this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en)
      //     })
      //     return arr.join(', ')
      //   } else {
      //     const data = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === ids)
      //     return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      //   }
      // },
      getCommodityGroupName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.commodityGroupList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.commodityGroupList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getCommoditySubGroupList (commodityGroupList) {
        const commodityGroupIds = []
        commodityGroupList.forEach(item => {
          commodityGroupIds.push(parseInt(item.value))
        })
        this.formData.com_grp_id = commodityGroupIds
        this.commoditySubGroupList = this.$store.state.Portal.agriObj.commoditySubGroupList.filter(item => commodityGroupIds.includes(item.commodity_group_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
        if (this.values_sub_group.length) {
          const arr = []
          this.values_sub_group.forEach(item => {
            const obj = this.commoditySubGroupList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_sub_group = arr
        }
        return this.commoditySubGroupList
      },
      getCommoditySubGroupName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.commoditySubGroupList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.commoditySubGroupList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getCommodityNameList (commoditySubGroupList) {
        const commoditySubGroupIds = []
        commoditySubGroupList.forEach(item => {
          commoditySubGroupIds.push(parseInt(item.value))
        })
        this.formData.com_subgrp_id = commoditySubGroupIds
        this.commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.filter(item => commoditySubGroupIds.includes(item.commodity_sub_group_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
        if (this.values_commodity.length) {
          const arr = []
          this.values_commodity.forEach(item => {
            const obj = this.commodityNameList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_commodity = arr
        }
        return this.commodityNameList
      },
      getCommodityName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === parseInt(id))
            arr.push(this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getNames (list) {
        const arr = []
        list.forEach(item => {
          arr.push(item?.text)
        })
        const str = arr.join(', ')
        return str.length > 50 ? str.substring(0, 40) + '..' : str
      }
    }
}
</script>
